import React from 'react';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';
import { Skeleton } from '@cex-newsletter-web/app-screen-skeleton';

const AsyncHome = lazyForPaint(
    () => import(/* webpackChunkName: "async-home" */ './index')
        .then(({ Home }) => Home)
);

export const Home = () => (
    <LazySuspense fallback={<Skeleton />}>
        <AsyncHome />
    </LazySuspense>
);
