import React from 'react';
import {
    Router,
    RouteComponent,
    createBrowserHistory
} from 'react-resource-router';
import { createResourcesPlugin } from 'react-resource-router/resources';
import { useAppRoutes } from '@cex-newsletter-web/app-routes';

const history = createBrowserHistory();
const resourcesPlugin = createResourcesPlugin({});

export const App = () => {
    const appRoutes = useAppRoutes();

    return (
        <Router routes={appRoutes} history={history} plugins={[resourcesPlugin]}>
            <RouteComponent />
        </Router>
    );
};
