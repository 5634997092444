import React from 'react';
import { Seo } from '@cex-newsletter-web/seo';
import { Redirect } from 'react-resource-router';
import { NewsletterSubscribe } from '@cex-newsletter-web/newsletter-subscribe-screen-jess/src/async';
import { NewsletterSubscribeView } from '@cex-newsletter-web/newsletter-subscribe-jess/src/async';

export const useAppRoutes = () => [
    {
        name: 'subscribe-embed',
        path: '/subscribe/embed',
        exact: true,
        component: () => (
            <NewsletterSubscribeView />
        ),
        resources: []
    },
    {
        name: 'subscribe',
        path: '/subscribe',
        exact: true,
        component: () => (
            <>
                <Seo title='Subscribe' />
                <NewsletterSubscribe />
            </>
        ),
        resources: []
    },
    // If route is not found, redirect to home
    {
        name: 'home',
        path: '/',
        component: () => (
            <Redirect to='http://jessdavisofficial.com/' />
        ),
        resources: []
    }
];
