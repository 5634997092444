import styled, { css } from 'styled-components';
import { Link } from 'react-resource-router';
import { forLargeDevices, forExtraSmallDevices } from '@cex-newsletter-web/app-theme';

const HEADER_HEIGHT = 50;
const HEADER_PADDING = 10;
export const LOGO_SIZE = HEADER_HEIGHT - HEADER_PADDING * 2 - 5;

export const commonContentPositionStyle = css`
    width: 100%;
    max-width: 1000px;
    position: relative;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
`;

export const ScreenRoot = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

export const Screen = styled.div`
    background-color: ${({ theme }) => theme.colors.background};
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;

    ${forLargeDevices('width: 100%;')}
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.primary};
    box-shadow: ${({ hasBorderBottom }) => hasBorderBottom ? '0 0 2px black' : 'none'};
    height: ${HEADER_HEIGHT}px;
    padding: ${HEADER_PADDING}px;
    box-sizing: border-box;
    z-index: 1;
`;

export const HeaderBrand = styled(Link)`
    display: flex;
    flex-direction: row;
    color: ${({ theme }) => theme.colors.onPrimary};
    text-decoration: none;
    
    &:hover {
        color: ${({ theme }) => theme.colors.onPrimary};
    }
`;

export const HeaderLogo = styled.img`
    height: ${LOGO_SIZE}px;
`;

export const HeaderText = styled.div`
    font-size: ${LOGO_SIZE}px;
    line-height: ${LOGO_SIZE}px;
    margin-left: 10px;
    font-weight: 600;
`;

export const Content = styled.div`
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
`;

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.onPrimary};
`;

export const Footer = styled.div`
    ${commonContentPositionStyle};
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 380px;
`;

export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;

  ${({ isFlex }) => forExtraSmallDevices(
        isFlex && 'flex-direction: column;'
    )}
`;

export const FooterColumn = styled.div`
    ${({ isFill }) => isFill && `
        flex: 1;
        align-items: center;
        justify-content: center;
        display: flex;
    `}

    &:not(:first-child) {
        margin-left: 100px;
    }

    ${({ isFlex }) => forExtraSmallDevices(
        isFlex && `
            &:not(:first-child) {
                margin-top: 20px;
                margin-left: 0;
            }
        `
    )}
`;

export const NavLinks = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const NavLink = styled(Link)`
    text-decoration: none;
    white-space:pre;
    color: inherit;
    &:hover {
        color: ${({ theme }) => theme.colors.onPrimaryHover};

        & > div {
            color: ${({ theme }) => theme.colors.onPrimaryHover}!important;
        }
    }
`;

export const Copyright = styled.div`
    text-decoration: none;
    margin-top: 40px;
`;

export const FooterSeparator = styled.div`
    background-color: ${({ theme }) => theme.colors.onPrimary};
    height: 2px;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
`;

export const Title = styled.div`
    font-size: 36px;
    ${forExtraSmallDevices(`
        font-size: 20px;
    `)}
`;
