import React from 'react';
import { AppPage } from '@cex-newsletter-web/app-page';
import { Spinner } from '@fluentui/react-components';
import { ContentContainer } from './styled';

export const Skeleton = () => (
    <AppPage>
        <ContentContainer>
            <Spinner
                size='huge'
                label='Loading...'
                labelPosition='below'
            />
        </ContentContainer>
    </AppPage>
);
