import React from 'react';
import logo from './assets/logo-jess.png';
import {
    Header,
    HeaderBrand,
    HeaderLogo,
    HeaderText
} from './styled';

export const AppHeader = ({ isContentScrolled }) => (
    <Header hasBorderBottom={isContentScrolled}>
        <HeaderBrand to='/'>
            <HeaderLogo src={logo} />
            <HeaderText>Jess Davis</HeaderText>
        </HeaderBrand>
    </Header>
);
