import React from 'react';
import FontAwesome6 from 'react-native-vector-icons/dist/FontAwesome6';
import { useTheme } from '@cex-newsletter-web/app-theme';
import {
    FooterContainer,
    Footer,
    FooterRow,
    FooterColumn,
    NavLinks,
    NavLink,
    Copyright,
    FooterSeparator,
    Title
} from './styled';

export const AppFooter = () => {
    const { colors } = useTheme();

    return (
        <FooterContainer>
            <Footer>
                <FooterRow>
                    <FooterColumn>
                        <Title>
                            Admin Console
                        </Title>
                        <FooterSeparator />
                    </FooterColumn>
                </FooterRow>
                <FooterRow isFlex>
                    <FooterColumn isFlex>
                        <NavLink to='/#'>Home</NavLink>
                        <br />
                        <NavLink to='/link1'>Link 1</NavLink>
                        <br />
                        <NavLink to='/link2'>Link 2</NavLink>
                    </FooterColumn>
                    <FooterColumn isFlex>
                        <NavLink to='/link3'>Link 3</NavLink>
                        <br />
                        <NavLink to='/link4'>Link 4</NavLink>
                        <br />
                        <NavLink to='/link5'>Link 5</NavLink>
                    </FooterColumn>
                </FooterRow>
                <FooterRow>
                    <FooterColumn>
                        <br />
                        <br />
                        <NavLinks>
                            <NavLink
                                to='www.tiktok'
                                target='_blank'
                            >
                                <FontAwesome6 name='tiktok' size={26} color={colors.onPrimary} brand />
                            </NavLink>
                            &nbsp;
                            &nbsp;
                            <NavLink
                                to='www.instagram'
                                target='_blank'
                            >
                                <FontAwesome6 name='instagram' size={30} color={colors.onPrimary} />
                            </NavLink>
                            &nbsp;
                            &nbsp;
                            <NavLink
                                to='www.threads'
                                target='_blank'
                            >
                                <FontAwesome6 name='square-threads' size={30} color={colors.onPrimary} brand />
                            </NavLink>
                            &nbsp;
                            &nbsp;
                            <NavLink
                                to='www.facebook'
                                target='_blank'
                            >
                                <FontAwesome6 name='facebook' size={27} color={colors.onPrimary} />
                            </NavLink>
                            &nbsp;
                            &nbsp;
                            <NavLink
                                to='www.reddit'
                                target='_blank'
                            >
                                <FontAwesome6 name='reddit' size={30} color={colors.onPrimary} />
                            </NavLink>
                            &nbsp;
                            &nbsp;
                            <NavLink
                                to='www.youtube'
                                target='_blank'
                            >
                                <FontAwesome6 name='youtube' size={30} color={colors.onPrimary} />
                            </NavLink>
                        </NavLinks>
                        <Copyright>
                            Copyright © 2024 CeX Newsletter
                        </Copyright>
                    </FooterColumn>
                </FooterRow>
            </Footer>
        </FooterContainer>
    );
};
