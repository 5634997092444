import './index.css';
import './react-native-vector-icons';
import React, {
    useEffect,
    useState
} from 'react';
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { App } from '@cex-newsletter-web/app';
import { awsConfig } from '@cex-newsletter/aws-amplify/src/aws.config';
import { GlobalStyle, AppThemeProvider } from '@cex-newsletter-web/app-theme';
import reportWebVitals from './reportWebVitals';

const AppRoot = () => {
    const [isInitializationStarted, setIsInitializationStarted] = useState(false);
    const [
        areIndependantPrerequisitesLoaded,
        setAreIndependantPrerequisitesLoaded
    ] = useState(false);
    const [arePrerequisitesLoaded, setArePrerequisitesLoaded] = useState(false);

    // Independent prerequisites
    useEffect(() => {
        if (!isInitializationStarted) {
            setIsInitializationStarted(true);
            Promise.all([
                // TODO ass prerequisites if needed
                Promise.resolve()
            ]).then(() => setAreIndependantPrerequisitesLoaded(true));
        }
    }, [
        isInitializationStarted,
        setIsInitializationStarted,
        setAreIndependantPrerequisitesLoaded
    ]);

    useEffect(() => {
        const canLoadPrerequisites = areIndependantPrerequisitesLoaded && !arePrerequisitesLoaded;
        if (canLoadPrerequisites) {
            // initializeAnalytics();
            // configAdapter(awsConfig.awsmobile);
            // Must to be called after initializeAnalytics
            Amplify.configure(awsConfig.awsmobile);
            setArePrerequisitesLoaded(true);
        }
    }, [
        areIndependantPrerequisitesLoaded,
        arePrerequisitesLoaded,
        setArePrerequisitesLoaded
    ]);

    const isAppLoaded = arePrerequisitesLoaded;
    if (!isAppLoaded) {
        return null;
    }

    return (
        <App />
    );
};

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <AppThemeProvider>
            <GlobalStyle />
            <AppRoot />
        </AppThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
