import React from 'react';
import {
    ThemeProvider as StyledThemeProvider,
    createGlobalStyle
} from 'styled-components';
import { FluentProvider } from '@fluentui/react-components';
import { CustomerEmailConsumer } from '@cex-newsletter/aws-amplify/src/models';
import { fluentUiTheme as fluentUiAdminTheme } from './theme-admin';
import { fluentUiTheme as fluentUiJessTheme } from './theme-jess';

const fluentUiTheme = {
    [CustomerEmailConsumer.JESS_DAVIS]: fluentUiJessTheme
}[process.env.REACT_APP_CONSUMER] ?? fluentUiAdminTheme;

/* const iconsMap = {
    'dance-pole': ({ size, color }) => (
        <DancePoleIcon
            width={size}
            height={size}
            fill={color}
        />
    ),
    link: ({ size, color }) => (
        <LinkIcon
            width={size}
            height={size}
            fill={color}
        />
    )
}; */

/* const Icon = props => {
    const { name } = props;
    const Icon = iconsMap[name];
    return Icon ? (
        <Icon {...props} />
    ) : (
        null
    );
}; */

const styledTheme = {
    colors: {
        primary: fluentUiTheme.colorBrandBackground,
        onPrimary: fluentUiTheme.colorNeutralForegroundOnBrand,
        onPrimaryHover: fluentUiTheme.colorBrandBackgroundInvertedHover,
        background: fluentUiTheme.colorNeutralBackground1,
        text: fluentUiTheme.colorNeutralForeground1,
        errorBorder: fluentUiTheme.colorPaletteRedBorder2
    }
    /* colors: {
        primary: fluentUiTheme.palette.themePrimary,
        primaryLight: pSBC(0.7, fluentUiTheme.palette.themePrimary),
        onPrimary: fluentUiTheme.palette.white,
        primaryAlt: fluentUiTheme.palette.themeLighterAlt,
        primaryAltHover: fluentUiTheme.palette.themeLighter,
        neutralLighter: fluentUiTheme.palette.neutralLighter,
        neutralLight: fluentUiTheme.palette.neutralLight,
        primaryHover: fluentUiTheme.palette.themeDarker,
        background: fluentUiTheme.palette.white,
        text: fluentUiTheme.palette.neutralPrimary,
        warning: '#cfac00',
        error: '#d40000'
    },
    style: {
        imgBlackToPrimaryFilter: 'invert(48%) sepia(88%) saturate(2912%) hue-rotate(171deg) brightness(96%) contrast(98%)',
        imgBlackToWhiteFilter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(14deg) brightness(101%) contrast(101%)'
    },
    fonts: {
        tiny: fluentUiTheme.fonts.tiny,
        xSmall: fluentUiTheme.fonts.xSmall,
        small: fluentUiTheme.fonts.small,
        smallPlus: fluentUiTheme.fonts.smallPlus,
        medium: fluentUiTheme.fonts.medium,
        mediumPlus: fluentUiTheme.fonts.mediumPlus,
        large: fluentUiTheme.fonts.large,
        xLarge: fluentUiTheme.fonts.xLarge,
        xLargePlus: fluentUiTheme.fonts.xLargePlus,
        xxLarge: fluentUiTheme.fonts.xxLarge,
        xxLargePlus: fluentUiTheme.fonts.xxLargePlus,
        superLarge: fluentUiTheme.fonts.superLarge,
        mega: fluentUiTheme.fonts.mega
    },
    Icon */
};

export const GlobalStyle = createGlobalStyle`
  html, body {
    background: ${styledTheme.colors.background};
  }
`;

export const useTheme = () => styledTheme;

export const AppThemeProvider = ({ children }) => (
    <StyledThemeProvider theme={styledTheme}>
        <FluentProvider theme={fluentUiTheme}>
            {children}
        </FluentProvider>
    </StyledThemeProvider>
);
