import React, { useState } from 'react';
import { AppHeader } from './header';
import { AppFooter } from './footer';
import {
    ScreenRoot,
    Screen,
    Content,
    commonContentPositionStyle
} from './styled';

export const AppPage = ({ children }) => {
    const [isContentScrolled, setIsContentScrolled] = useState(false);

    const handleContentScroll = event => {
        setIsContentScrolled(event.target.scrollTop > 5);
    };

    return (
        <ScreenRoot>
            <Screen>
                <AppHeader isContentScrolled={isContentScrolled} />
                <Content onScroll={handleContentScroll}>
                    {children}
                    <AppFooter />
                </Content>
            </Screen>
        </ScreenRoot>
    );
};

export { commonContentPositionStyle };
