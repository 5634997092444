import { createLightTheme } from '@fluentui/react-components';

// Built via https://aka.ms/themedesigner-v9
// Main color #FF2EB2, Hue: -50, Vibrancy: 50
const theme = {
    10: '#000000',
    20: '#28080D',
    30: '#45031A',
    40: '#5F0028',
    50: '#780039',
    60: '#91004B',
    70: '#AC005F',
    80: '#C60076',
    90: '#E2008E',
    100: '#F81EA8',
    110: '#FF4FBD',
    120: '#FF76CD',
    130: '#FF96DC',
    140: '#FFB2E9',
    150: '#FFCCF3',
    160: '#FFE6FB'
};

export const fluentUiTheme = createLightTheme(theme);
