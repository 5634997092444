import React from 'react';
import Helmet from 'react-helmet';
import uniq from 'lodash/uniq';
import { CustomerEmailConsumer } from '@cex-newsletter/aws-amplify/src/models';

const config = {
    [CustomerEmailConsumer.JESS_DAVIS]: {
        titleTemplate: '%s | Jess Davis',
        description: 'Subscribe to Jess Davis newsletter. Receive latest news and updates from Jess Davis',
        author: 'pleasurehub.sex',
        keywords: []
    }
}[process.env.REACT_APP_CONSUMER] ?? {
    titleTemplate: '%s | Admin',
    description: '',
    author: 'codexplorer.io',
    keywords: []
};

export const noCache = [
    {
        'http-equiv': 'Cache-Control',
        content: 'no-cache, no-store, must-revalidate'
    },
    {
        'http-equiv': 'Pragma',
        content: 'no-cache'
    },
    {
        'http-equiv': 'Expires',
        content: '0'
    }
];

export const Seo = ({
    description = config.description,
    lang = 'en',
    meta = [],
    keywords = [],
    title,
    image
}) => {
    const metaKeywords = uniq([
        ...config.keywords,
        ...keywords
    ]);

    const {
        author,
        titleTemplate
    } = config;

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate={titleTemplate}
            meta={[
                {
                    name: 'description',
                    content: description
                },
                ...(
                    metaKeywords.length > 0 ?
                        [{
                            name: 'keywords',
                            content: metaKeywords.join(', ')
                        }] :
                        []
                ),
                {
                    property: 'author',
                    content: author
                },
                {
                    property: 'og:title',
                    content: title
                },
                {
                    property: 'og:description',
                    content: description
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                ...(
                    image ?
                        [{
                            property: 'og:image',
                            content: image
                        }] :
                        []
                ),
                {
                    name: 'twitter:card',
                    content: 'summary'
                },
                {
                    name: 'twitter:creator',
                    content: author
                },
                {
                    name: 'twitter:title',
                    content: title
                },
                {
                    name: 'twitter:description',
                    content: description
                },
                ...meta
            ]}
        />
    );
};
