import React from 'react';
import { Seo } from '@cex-newsletter-web/seo';
import { Home } from '@cex-newsletter-web/home-screen-admin/src/async';

export const useAppRoutes = () => [
    /* {
        name: 'terms-and-conditions',
        path: '/terms-and-conditions',
        exact: true,
        component: () => (
            <>
                <Seo title='Terms & Conditions' />
                <TermsAndConditions />
            </>
        ),
        resources: []
    }, */
    // If route is not found, redirect to home
    {
        name: 'home',
        path: '/',
        component: () => (
            <>
                <Seo title='Home' />
                <Home />
            </>
        ),
        resources: []
    }
];
