import React from 'react';
import FontAwesome6 from 'react-native-vector-icons/dist/FontAwesome6';
import { useTheme } from '@cex-newsletter-web/app-theme';
import {
    FooterContainer,
    Footer,
    FooterRow,
    FooterColumn,
    NavLinks,
    NavLink,
    Copyright,
    FooterSeparator,
    Title
} from './styled';

export const AppFooter = () => {
    const { colors } = useTheme();

    return (
        <FooterContainer>
            <Footer>
                <FooterRow>
                    <FooterColumn>
                        <Title>
                            Jess Davis Newsletter
                        </Title>
                        <FooterSeparator />
                    </FooterColumn>
                </FooterRow>
                <FooterRow isFlex>
                    <FooterColumn isFlex>
                        <NavLink to='/'>Home</NavLink>
                        <br />
                        <NavLink to='/subscribe'>Newsletter</NavLink>
                    </FooterColumn>
                </FooterRow>
                <FooterRow>
                    <FooterColumn>
                        <br />
                        <br />
                        <NavLinks>
                            <NavLink
                                to='https://www.fanvue.com/foxysisters'
                                target='_blank'
                            >
                                <FontAwesome6 name='face-kiss-wink-heart' size={27} color={colors.onPrimary} />
                            </NavLink>
                            &nbsp;
                            &nbsp;
                            <NavLink
                                to='https://www.instagram.com/jessdaviscandy'
                                target='_blank'
                            >
                                <FontAwesome6 name='instagram' size={30} color={colors.onPrimary} />
                            </NavLink>
                            &nbsp;
                            &nbsp;
                            <NavLink
                                to='https://www.threads.net/@jessdaviscandy'
                                target='_blank'
                            >
                                <FontAwesome6 name='square-threads' size={30} color={colors.onPrimary} brand />
                            </NavLink>
                            &nbsp;
                            &nbsp;
                            <NavLink
                                to='https://www.tiktok.com/@jessdaviscandy'
                                target='_blank'
                            >
                                <FontAwesome6 name='tiktok' size={26} color={colors.onPrimary} brand />
                            </NavLink>
                            &nbsp;
                            &nbsp;
                            <NavLink
                                to='https://www.youtube.com/@jessdaviscandy'
                                target='_blank'
                            >
                                <FontAwesome6 name='youtube' size={30} color={colors.onPrimary} />
                            </NavLink>
                        </NavLinks>
                        <Copyright>
                            Copyright © 2024 Jess Davis
                        </Copyright>
                    </FooterColumn>
                </FooterRow>
            </Footer>
        </FooterContainer>
    );
};
