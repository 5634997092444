import React from 'react';
import { LazySuspense, lazyForPaint } from 'react-loosely-lazy';
import { Skeleton } from '@cex-newsletter-web/app-screen-skeleton';

const AsyncNewsletterSubscribe = lazyForPaint(
    () => import(/* webpackChunkName: "async-newsletter-subscribe-jess" */ './index')
        .then(({ NewsletterSubscribe }) => NewsletterSubscribe)
);

export const NewsletterSubscribe = () => (
    <LazySuspense fallback={<Skeleton />}>
        <AsyncNewsletterSubscribe />
    </LazySuspense>
);
