// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CustomerEmailConsumer = {
  "JESS_DAVIS": "JESS_DAVIS"
};

const { CustomerEmail } = initSchema(schema);

export {
  CustomerEmail,
  CustomerEmailConsumer
};